<template>
  <div class="goods_list">
    <el-table :data="tableData" style="width: 100%;" v-loading="loading">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="image" label="图片">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="scope.row.image" fit="fill"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="specific" label="规格"></el-table-column>
      <el-table-column prop="price" label="价格" width="100"></el-table-column>
      <el-table-column prop="provider" label="供应商"></el-table-column>
      <el-table-column prop="stock" label="库存" width="100"></el-table-column>
      <el-table-column prop="plat" label="平台" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="getplatdata(scope.row.id,scope.$index)">{{ scope.row.plat === null ? 0 : scope.row.plat}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status == 1">已发布</el-tag>
          <el-tag type="info" v-if="scope.row.status == 2">待发布</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除该单位吗？" @confirm="handleDelete(scope.$index, scope.row)">
            <el-button size="mini" type="danger" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next, total" :page-size = 5 @current-change = "handle_page_current" :current-page = "page" :total="count"></el-pagination>
    <el-dialog title="样品平台管理" :visible.sync="dialogFormVisible">
      <el-form label-position="right">
        <el-row :gutter="10" v-for="plat in plats" :key="plat.index">
          <el-col :span="4">
            <el-form-item>
              <el-input v-model="plat.name" autocomplete="off" placeholder="填写平台名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item>
              <el-input v-model="plat.link" autocomplete="off" placeholder="填写平台链接"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" icon="el-icon-delete" plain @click.prevent="removePlat(plat)"></el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22"><el-button icon="el-icon-plus" plain style="width:100%;" size="small" @click="addPlat"></el-button></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePlatInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Goods_list',
  data () {
    return {
      page: 1,
      count: 0,
      loading: true,
      tableData: [],
      dialogFormVisible: false,
      plats: [],
      gid: '',
      row: ''
    }
  },
  methods: {
    handleEdit (gid) {
      this.$router.push({ path: `/goods_edit/${gid}` })
    },
    handle_page_current (e) {
      this.getData(e)
    },
    // 删除一个样品
    handleDelete (index, row) {
      var that = this
      this.axios.delete('/admin/goods', {
        params: {
          id: row.id
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          that.$message({
            message: response.data.msg,
            type: 'success'
          })
          that.getData(that.page)
        } else {
          that.$message.error('操作失败！')
        }
      })
        .catch(function (error) {
          console.log(error)
        })
    },
    addPlat () {
      if (this.plats.length < 5) {
        this.plats.push({ gid: this.gid, name: '', link: '' })
      } else {
        this.$message.warning('最多只能添加5个平台')
      }
    },
    removePlat (plat) {
      var index = this.plats.indexOf(plat)
      this.plats.splice(index, 1)
    },
    getplatdata (gid, row) {
      var that = this
      this.gid = gid
      this.row = row
      this.axios.get('/admin/plat/' + gid)
        .then(function (response) {
          that.plats = response.data
          that.dialogFormVisible = true
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updatePlatInfo () {
      var that = this
      this.axios.post('/admin/plat', {
        gid: that.gid,
        plats: that.plats
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.tableData[that.row].plat = that.plats.length
          } else {
            that.$message.error('发生错误')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
      this.dialogFormVisible = false
    },
    getData (e) {
      var that = this
      that.loading = true
      this.axios.get('/admin/goods/?page=' + e)
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
            that.loading = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getData(this.page)
  }
}
</script>
